import React, { Component } from 'react'

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      feedback: '', name: 'Name', email: 'email@example.com'
     };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    }
    
  render() {
    return (
              <div>

                <div className="Contact">
                  <form className="test-mailing">
                    <p>Shoot me an email with your inquiry and contact info and I will get back to you!</p>
                      <div>
                        <textarea
                        id="portfolioinquery"
                        name="portfolioinquery"
                        onChange={this.handleChange}
                        placeholder="inquery and contact info"
                        required
                        value={this.state.feedback}
                        style={{width: '100%', height: '150px'}}
                        />
                      </div>
                    <input type="button" value="send" className="btn btn--submit" onClick={this.handleSubmit} />
                  </form>
                </div>

              </div>
    )
  }

    handleChange(event) {
      this.setState({feedback: event.target.value})
    }

    handleSubmit (event) {
      const templateId = 'portfolioinquery';

      this.sendFeedback(templateId, {message_html: this.state.feedback, from_name: this.state.name, reply_to: this.state.email})
    }
  
    sendFeedback (templateId, variables) {
      window.emailjs.send(
      'gmail', templateId,
      variables
      ).then(res => {
      console.log('Email successfully sent!')
      this.setState({feedback: 'Email successfully sent!'})

      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => console.error('Email failed. Here are some thoughts on the error that occured:', err))
    }
}