import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

export default class Projects extends Component {
  render() {
    return (
      <div>
        
            <div className="Projects">
                
                <p>Checkout my github to see previous and current work! Click here!{">>"} <a href="https://github.com/rossfletcher19" target="blank" ><FontAwesomeIcon icon={faGithub} /></a></p>
            </div>
        
      </div>
    )
  }
}