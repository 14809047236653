import React, { Component } from 'react';
import './App.css';
import Introduction from './components/introduction';
import About from './components/about';
import Projects from './components/projects';
import Contact from './components/contact';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Navbar, Nav } from 'react-bootstrap'
import { BrowserRouter as Router, Route, NavLink} from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

const routes = [
  { path: '/', name: 'Home', Component: Introduction },
  { path: '/about', name: 'About', Component: About },
  { path: '/contact', name: 'Contact', Component: Contact },
  { path: '/projects', name: 'Projects', Component: Projects },
]

class App extends Component {
  

  render() {
  
    return (
      
      <div className="App">
        
          <Router>
          
          
          <Container>
                <Navbar collapseOnSelect expand="lg">
                    <Navbar.Brand href="/">RF</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="">
                          <Nav className="mr-auto">
                          {routes.map(route => (
                          <Nav.Link
                            eventKey="1"
                            key={route.path}
                            as={NavLink}
                            to={route.path}
                            activeClassName="active"
                            exact
                          >
                            {route.name}
                          </Nav.Link>
                        ))}
                          
                          </Nav>
                      
                      </Navbar.Collapse>
                  </Navbar>
            <Row className="row1">
              
                <Col className="col1">

                          {routes.map(({ path, Component }) => (
                              <Route key={path} exact path={path}>
                                  {({ match }) => (
                                      <CSSTransition
                                        in={match != null}
                                        timeout={1000}
                                        classNames="page"
                                        unmountOnExit
                                      >
                                      <div className="page">
                                          <Component />
                                      </div>
                                      </CSSTransition>
                                  )}
                              </Route>
                          ))}

                </Col>

            </Row>
              <div className="sidebarfooter">
                            <p><small>
                                <br></br>
                                Built with <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">ReactJS</a> 
                            </small></p>
              </div>
            
            
          </Container>
          </Router>
      </div>
    )

      


  }
  

}

export default App;
